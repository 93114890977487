<template>
  <div class="wrap">
    <div class="search-container">
      <el-row>
        <el-col :span="20">
          <el-form ref="form" :model="form" label-width="120px">
            <el-row>
              <el-col :span="6">
                <el-form-item label="设备名称">
                  <el-input clearable v-model="form.device_nm" placeholder="请输入设备名称"></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="6">
                <el-form-item label="告警类型">
                  <el-select clearable v-model="form.report_type">
                    <el-option :value="item.value" :label="item.label" v-for="(item, index) in DROPDOWNBOX.DEVICE_ALARM_TYPE" :key="`device_alarm_type_${index}`"></el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="4">
          <el-button @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
				:data="tableData"
        :height="tableHeight"
				style="width: 100%"
        border
        :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
        <el-table-column
					type="index"
          align="center"
					label="序号"
					width="55">
				</el-table-column>
				<el-table-column
					type="selection"
          align="center"
					width="55">
				</el-table-column>
				<el-table-column
					prop="deviceNm"
          align="center"
					label="设备名称">
				</el-table-column>
        <el-table-column
					prop="deviceID"
          align="center"
					label="设备ID">
				</el-table-column>
        <el-table-column
					prop="alarmContent"
          align="center"
					label="告警内容">
				</el-table-column>
        <el-table-column
					prop="reportTime"
          align="center"
					label="上报时间">
				</el-table-column>
			</el-table>
    </div>
    <div class="page-container">
      <el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="form.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="form.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="form.total">
			</el-pagination>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import SupplierClass from './supplierClass'
import apis from '@/apis'
import mixin from '@/mixins/index'

export default {
  mixins: [mixin],
  data() {
    return {
      form: new SupplierClass('form'), // 实例化一个表单的变量
      tableData: [],
    }
  },
  computed: {
    ...mapState(['Language']),
  },
  methods: {
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.post(apis.alarmRecordList, this.form).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows.deviceAlarmRecordOutputDtos
          this.form.total = res.data.rows.total
        }
      })
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new SupplierClass('form')
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
